@import './_variables.scss';

.banner {
  position: relative;
  height: 75vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay-color;
  }

  .banner-content {
    position: relative;
    z-index: 1;
    text-align: center;

    .banner-title {
      color: $white;
      font-size: 5rem;
      font-family: $font-family;
      text-transform: capitalize;
    }
  }
}