@import './_variables.scss';

.form-section {
  padding: 6rem 2rem;
  text-align: center;
  max-width: 100%;
  border-top: 1px solid #c7a657;
  border-bottom: 1px solid #c7a657;

  .inner-container {
    background-image: $blue-overlay-background;
    background-color: $main-site-blue;
    padding: 4rem 6rem 4rem 5rem;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-details-container {
      width: 50%;
      padding: unset;
      color: $white;
      text-align: left;
      padding: 0 3rem 0 0;


      h1 {
        font-size: 3rem;
        margin-top: 0;
        font-family: $font-family;

      }
    }

    .form-container {
      width: 50%;
      padding: 0 0 0 2rem;
      min-height: 509px;

      form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .input-container {
          min-height: 5.3rem;

          &.phone-container {
            .react-phonenr-input {
              input {
                align-items: center;
                background-color: transparent;
                border-color: hsl(0, 0%, 80%);
                border-radius: 0;
                border-style: solid;
                border-width: 1px;
                cursor: default;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                min-height: 3.1rem;
                outline: 0 !important;
                position: relative;
                transition: all 100ms;
                box-sizing: border-box;
                color: $white !important;
                border: none;
                border-bottom: 3px solid rgba($color: white, $alpha: 1);
                cursor: text;
                width: 100%;
                margin-right: 0;
                padding-left: 20px;
                font-family: $font-family;
              }

              .flag-wrapper {
                border: none;
                border-bottom: 3px solid rgba($color: white, $alpha: 1);
                border-radius: 0;
                background-color: transparent;
                justify-content: unset;
                cursor: pointer;

                .selected-flag {
                  &:hover {
                    background-color: transparent;
                  }
                }

                &::after {
                  border: 4px solid transparent;
                  border-top-color: $white;
                  content: "";
                  height: 0;
                  pointer-events: none;
                  position: absolute;
                  right: -15px;
                  top: 55%;
                  transform: translateY(-50%);
                  width: 0;
                }
              }

              .country-list-item {
                font-family: $font-family;
                background-color: $main-site-blue;
                color: $white;
              }
            }
          }

          .text-input {
            align-items: center;
            background-color: transparent;
            border-color: rgba($color: white, $alpha: 1);
            border-radius: 0;
            border-style: solid;
            border-width: 1px;
            cursor: default;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            min-height: 3rem;
            outline: 0 !important;
            position: relative;
            transition: all 100ms;
            box-sizing: border-box;
            //check color
            color: $white !important;
            border: none;
            border-bottom: 3px solid rgba($color: white, $alpha: 1);
            margin-right: 3rem;
            cursor: text;
            width: 100%;
            font-family: $font-family;
            font-size: 16px;


            //   margin-bottom: 1.5rem;
            &::placeholder {
              color: $white !important;
              font-family: $font-family;
              text-transform: uppercase;
              font-size: 16px;
            }
          }

          .error {
            font-family: $font-family;
            color: red;
            margin-top: 0.5rem;
            font-size: 0.9rem;
            text-align: left;
          }
        }


        button {
          font-family: 'National-Medium';
          margin-top: 1rem;
          padding: 1rem 5rem;
          background-color: $black;
          color: $white;
          border: 3px solid $white;
          border-radius: 0.25rem;
          cursor: pointer;
          font-size: 1rem;
          text-transform: uppercase;
          transition: background-color 0.3s ease;
          background-color: $main-site-blue;

          &:hover {
            background-color: lighten($main-site-blue, 10%);
          }
        }
      }

      .file-upload-container {
        position: relative;
        padding: 10px 5px 5px 0;
        min-height: unset !important;
        height: 3rem;
        margin-bottom: 3rem;

        .file-input {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          cursor: pointer;
          color: #000;
          font-size: 16px;

          &:focus+.custom-file-label .file-upload-placeholder,
          &:hover+.custom-file-label .file-upload-placeholder {
            color: #000;
          }
        }

        .custom-file-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          cursor: pointer;
          padding: 10px 10px 10px 3px;
          border-bottom: 3px solid #fff;

          .file-upload-placeholder {
            color: $white !important;
            font-size: 16px;
            font-family: $font-family;
            text-transform: uppercase;
          }
        }

        .upload-icon {
          margin-left: auto;
          padding-left: 10px;
        }

        .upload-img {
          max-width: 20px;
        }
      }

      .loading {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .uploading-text-container{
          color: $white;
          font-size: 1.5rem;
          padding-top: 1rem;
        }
      }
      .lds-ring {
        display: inline-block;
        position: relative;
        width: 46px;
        height: 46px;
        &.blue {
          div {
            border-color: #88bcbb transparent transparent transparent;
          }
        }
        &.white {
          div {
            border-color: #fff transparent transparent transparent;
          }
        }
        // box-shadow: 0px 4px 10px 0px #cd3c4d80;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        margin: 3px;
        border: 1px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
      }
      
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }

      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
      
        100% {
          transform: rotate(360deg);
        }
      }

      .thankyou-container{
        display: flex;
        justify-content: center;
        padding: 9% 2rem 0;
        height: 100%;
        h3{
          font-size: 2rem;
          color: $white;
        }
      }
    }

    @media screen and (max-width: 1024px){
      flex-direction: column;
      padding: 4rem 3rem;
      .text-details-container {
        width: 100%;
        padding: 0 0 2rem 0;
        h1 {
          font-size: 2rem;
          margin-top: 0;
          font-family: $font-family;
  
        }
      }

      .form-container {
        width: 100%;
        padding: 0;

        .thankyou-container{
          padding: 25% 0 0;
        }

        .loading{
          padding-top: 35%;
        }
      }
    }

  }

  @media screen and (max-width: 1024px){
    padding: 5rem 1rem;
  }


}