@import './_variables.scss';

.footer {
  background-color: #0e2a43;
  padding: 20px 5rem;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;

  &__section {
    flex: 1;
    padding: 10px;

    h4 {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
    }

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &.footer__sales{
      flex-grow: 2;
    }
  }

  // Responsive Design
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 20px 2rem;

    &__section {
      margin-bottom: 0;

      h4{
        margin: 0;
      }
    }
  }
}