//Colors

$white: #fff;
$overlay-color: rgba(0, 0, 0, 0.1);
$black: #000;
$blue-overlay-background: linear-gradient(to bottom, rgba(15, 16, 41, 0), rgba(15, 16, 41, 0), rgba(15, 16, 41, .8), #0f1029);
$main-site-blue: #062240;

//font
$font-family: 'Minerva Modern',
sans-serif;