@import './_variables.scss';

.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1rem 2rem;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  .logo {
    color: $white;
    font-size: 1.5rem;
    font-weight: bold;
    width: clamp(240px, 25vw, 0px);
    &.SecondLogo{
      display: flex;
      justify-content: end;
      img{
        height: 2em;
      }
    }

    img {
      max-width: 100%;
    }
  }

  @media screen and (min-width: 1025px) {
    padding: 1.5rem 3rem;
  }
}
@media screen and (max-width: 1024px) {
  .navbar {
    .logo{
      width: 45%;
      img{
        height: 2.5em;
      }
      &.SecondLogo{
        img{
          height: 1.8em;
        }
      }
    }
  }
}