@import './_variables.scss';
@import './_navbar.scss';
@import './_banner.scss';
@import './_form.scss';
@import './_footer.scss';


@font-face {
  font-family: 'Minerva Modern';
  src: url('../assets/fonts/MinervaModernItalic.eot');
  src: url('../assets/fonts/MinervaModernItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/MinervaModernItalic.woff2') format('woff2'),
    url('../assets/fonts/MinervaModernItalic.woff') format('woff'),
    url('../assets/fonts/MinervaModernItalic.ttf') format('truetype'),
    url('../assets/fonts/MinervaModernItalic.svg#MinervaModernItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Minerva Modern';
  src: url('../assets/fonts/MinervaModernBlackItalic.eot');
  src: url('../assets/fonts/MinervaModernBlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/MinervaModernBlackItalic.woff2') format('woff2'),
    url('../assets/fonts/MinervaModernBlackItalic.woff') format('woff'),
    url('../assets/fonts/MinervaModernBlackItalic.ttf') format('truetype'),
    url('../assets/fonts/MinervaModernBlackItalic.svg#MinervaModernBlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Minerva Modern';
  src: url('../assets/fonts/MinervaModernBlack.eot');
  src: url('../assets/fonts/MinervaModernBlack.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/MinervaModernBlack.woff2') format('woff2'),
    url('../assets/fonts/MinervaModernBlack.woff') format('woff'),
    url('../assets/fonts/MinervaModernBlack.ttf') format('truetype'),
    url('../assets/fonts/MinervaModernBlack.svg#MinervaModernBlack') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Minerva Modern';
  src: url('../assets/fonts/MinervaModernBoldItalic.eot');
  src: url('../assets/fonts/MinervaModernBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/MinervaModernBoldItalic.woff2') format('woff2'),
    url('../assets/fonts/MinervaModernBoldItalic.woff') format('woff'),
    url('../assets/fonts/MinervaModernBoldItalic.ttf') format('truetype'),
    url('../assets/fonts/MinervaModernBoldItalic.svg#MinervaModernBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Minerva Modern';
  src: url('../assets/fonts/MinervaModernBold.eot');
  src: url('../assets/fonts/MinervaModernBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/MinervaModernBold.woff2') format('woff2'),
    url('../assets/fonts/MinervaModernBold.woff') format('woff'),
    url('../assets/fonts/MinervaModernBold.ttf') format('truetype'),
    url('../assets/fonts/MinervaModernBold.svg#MinervaModernBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-ExtraboldItalic';
  src: url('../assets/fonts/National-ExtraboldItalic.eot');
  src: url('../assets/fonts/National-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-ExtraboldItalic.woff2') format('woff2'),
      url('../assets/fonts/National-ExtraboldItalic.woff') format('woff'),
      url('../assets/fonts/National-ExtraboldItalic.ttf') format('truetype'),
      url('../assets/fonts/National-ExtraboldItalic.svg#National-ExtraboldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Black';
  src: url('../assets/fonts/National-Black.eot');
  src: url('../assets/fonts/National-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Black.woff2') format('woff2'),
      url('../assets/fonts/National-Black.woff') format('woff'),
      url('../assets/fonts/National-Black.ttf') format('truetype'),
      url('../assets/fonts/National-Black.svg#National-Black') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Light';
  src: url('../assets/fonts/National-Light.eot');
  src: url('../assets/fonts/National-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Light.woff2') format('woff2'),
      url('../assets/fonts/National-Light.woff') format('woff'),
      url('../assets/fonts/National-Light.ttf') format('truetype'),
      url('../assets/fonts/National-Light.svg#National-Light') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Book';
  src: url('../assets/fonts/National-Book.eot');
  src: url('../assets/fonts/National-Book.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Book.woff2') format('woff2'),
      url('../assets/fonts/National-Book.woff') format('woff'),
      url('../assets/fonts/National-Book.ttf') format('truetype'),
      url('../assets/fonts/National-Book.svg#National-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-BoldItalic';
  src: url('../assets/fonts/National-BoldItalic.eot');
  src: url('../assets/fonts/National-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/National-BoldItalic.woff') format('woff'),
      url('../assets/fonts/National-BoldItalic.ttf') format('truetype'),
      url('../assets/fonts/National-BoldItalic.svg#National-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Extrabold';
  src: url('../assets/fonts/National-Extrabold.eot');
  src: url('../assets/fonts/National-Extrabold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Extrabold.woff2') format('woff2'),
      url('../assets/fonts/National-Extrabold.woff') format('woff'),
      url('../assets/fonts/National-Extrabold.ttf') format('truetype'),
      url('../assets/fonts/National-Extrabold.svg#National-Extrabold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-BookItalic';
  src: url('../assets/fonts/National-BookItalic.eot');
  src: url('../assets/fonts/National-BookItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-BookItalic.woff2') format('woff2'),
      url('../assets/fonts/National-BookItalic.woff') format('woff'),
      url('../assets/fonts/National-BookItalic.ttf') format('truetype'),
      url('../assets/fonts/National-BookItalic.svg#National-BookItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Bold';
  src: url('../assets/fonts/National-Bold.eot');
  src: url('../assets/fonts/National-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Bold.woff2') format('woff2'),
      url('../assets/fonts/National-Bold.woff') format('woff'),
      url('../assets/fonts/National-Bold.ttf') format('truetype'),
      url('../assets/fonts/National-Bold.svg#National-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-BlackItalic';
  src: url('../assets/fonts/National-BlackItalic.eot');
  src: url('../assets/fonts/National-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-BlackItalic.woff2') format('woff2'),
      url('../assets/fonts/National-BlackItalic.woff') format('woff'),
      url('../assets/fonts/National-BlackItalic.ttf') format('truetype'),
      url('../assets/fonts/National-BlackItalic.svg#National-BlackItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-MediumItalic';
  src: url('../assets/fonts/National-MediumItalic.eot');
  src: url('../assets/fonts/National-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-MediumItalic.woff2') format('woff2'),
      url('../assets/fonts/National-MediumItalic.woff') format('woff'),
      url('../assets/fonts/National-MediumItalic.ttf') format('truetype'),
      url('../assets/fonts/National-MediumItalic.svg#National-MediumItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-SemiboldItalic';
  src: url('../assets/fonts/National-SemiboldItalic.eot');
  src: url('../assets/fonts/National-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-SemiboldItalic.woff2') format('woff2'),
      url('../assets/fonts/National-SemiboldItalic.woff') format('woff'),
      url('../assets/fonts/National-SemiboldItalic.ttf') format('truetype'),
      url('../assets/fonts/National-SemiboldItalic.svg#National-SemiboldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Medium';
  src: url('../assets/fonts/National-Medium.eot');
  src: url('../assets/fonts/National-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Medium.woff2') format('woff2'),
      url('../assets/fonts/National-Medium.woff') format('woff'),
      url('../assets/fonts/National-Medium.ttf') format('truetype'),
      url('../assets/fonts/National-Medium.svg#National-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-RegularItalic';
  src: url('../assets/fonts/National-RegularItalic.eot');
  src: url('../assets/fonts/National-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-RegularItalic.woff2') format('woff2'),
      url('../assets/fonts/National-RegularItalic.woff') format('woff'),
      url('../assets/fonts/National-RegularItalic.ttf') format('truetype'),
      url('../assets/fonts/National-RegularItalic.svg#National-RegularItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-LightItalic';
  src: url('../assets/fonts/National-LightItalic.eot');
  src: url('../assets/fonts/National-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-LightItalic.woff2') format('woff2'),
      url('../assets/fonts/National-LightItalic.woff') format('woff'),
      url('../assets/fonts/National-LightItalic.ttf') format('truetype'),
      url('../assets/fonts/National-LightItalic.svg#National-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-ThinItalic';
  src: url('../assets/fonts/National-ThinItalic.eot');
  src: url('../assets/fonts/National-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-ThinItalic.woff2') format('woff2'),
      url('../assets/fonts/National-ThinItalic.woff') format('woff'),
      url('../assets/fonts/National-ThinItalic.ttf') format('truetype'),
      url('../assets/fonts/National-ThinItalic.svg#National-ThinItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Thin';
  src: url('../assets/fonts/National-Thin.eot');
  src: url('../assets/fonts/National-Thin.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Thin.woff2') format('woff2'),
      url('../assets/fonts/National-Thin.woff') format('woff'),
      url('../assets/fonts/National-Thin.ttf') format('truetype'),
      url('../assets/fonts/National-Thin.svg#National-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Regular';
  src: url('../assets/fonts/National-Regular.eot');
  src: url('../assets/fonts/National-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Regular.woff2') format('woff2'),
      url('../assets/fonts/National-Regular.woff') format('woff'),
      url('../assets/fonts/National-Regular.ttf') format('truetype'),
      url('../assets/fonts/National-Regular.svg#National-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Semibold';
  src: url('../assets/fonts/National-Semibold.eot');
  src: url('../assets/fonts/National-Semibold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/National-Semibold.woff2') format('woff2'),
      url('../assets/fonts/National-Semibold.woff') format('woff'),
      url('../assets/fonts/National-Semibold.ttf') format('truetype'),
      url('../assets/fonts/National-Semibold.svg#National-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}






* {
  box-sizing: border-box;
}


body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
}